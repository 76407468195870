<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/V.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Velín
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Vítejte! 👋🏻
          </p>
          <p class="mb-2">
            Přihlaste se prosím ke svému účtu.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="username"
              outlined
              label="Login"
              placeholder="totéž co na vzdálenku"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Heslo"
              placeholder="totéž co na vzdálenku"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                v-model="permanent_login"
                label="Neodhlašovat"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                v-show="!show_btn_help"
                href="#"
                @click="showHelp"
                class="mt-1"
              >
                Jak se přihlásit?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loading_login_btn"
              @click="login"
            >
              Přihlásit se
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{name:'pages-register'}">
            Create an account
          </router-link>
        </v-card-text> -->

        <!-- login with google  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Máte GOOGLE účet?
          </span>
          <!-- <router-link :to="{name:'pages-google'}">
            Přihlaste se zde
          </router-link> -->
          <span @click="loginWithGoogle()" style="cursor:pointer;" onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">Přihlaste se zde</span>
        </v-card-text>

        <!-- message err + help -->
        <v-card-text
          class="d-flex align-center justify-center flex-wrap mt-2"
        >
          <div
            v-if="show_btn_help"
            class="me-2"
            style="color:#FF4B51;text-align:center;min-height:42px"
          >
            {{ login_msg }}
          </div>
          <div
            v-else
            style="line-height:42px">
            &nbsp;
          </div>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">INDEX NOSLUŠ s.r.o.</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <span
            v-for="link in socialLink"
            :key="link.icon"
          >
            <v-btn
              icon
              class="ms-1"
              v-if="link.active===true"
            >
              <a :href="link.link" target="_blank">
                <v-icon
                  :color="$vuetify.theme.dark ? link.colorInDark : link.color"
                >
                  {{ link.icon }}
                </v-icon>
              </a>
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline, mdiWeb } from '@mdi/js'
import { ref } from '@vue/composition-api'
import response from '@/mixins/response'
import google from '@/mixins/google'
import { AUTH_REQUEST } from '../../store/actions/auth'

export default {
  mixins: [response, google],
  name: 'pages-login',
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiWeb,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
        active: true,
        link: 'https://indexnoslus.cz',
      },
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
        active: true,
        link: 'https://www.facebook.com/indexnosluscr',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
        active: false,
        link: 'https://',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
        active: false,
        link: 'https://',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
        active: false,
        link: 'https://',
      },
    ]

    return {
      isPasswordVisible,
      username,
      password,
      socialLink,

      permanent_login: false,
      loading_login_btn: false,
      show_btn_help: false,

      login_msg: '',
      forgot_pass_msg: 'Použijte stejné jméno a heslo jako na vzdálenou plochu nebo kontaktuje IT podporu!',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  created() {
    const token_permit = localStorage.getItem('user-token_permit')
    if (token_permit) {
      this.loginWithToken(token_permit)
    }
  },
  methods: {
    login() {
      if (!this.username) {
        return
      }
      if (!this.password) {
        return
      }

      // ---
      this.loading_login_btn = true
      this.username = this.username.toLowerCase().replace('noslus', '').replace('\\', '')
      this.$store.dispatch(AUTH_REQUEST, { username: this.username, password: this.password, permanent_login: this.permanent_login }).then(() => {
        this.loading_login_btn = false
        this.$router.push('/')
      }).catch(err => {
        this.loading_login_btn = false
        this.login_msg = this.responseError(err)
        this.show_btn_help = true

        setTimeout(() => {
          this.show_btn_help = false
        }, 10000)
      })
    },
    loginWithToken(p_token_permit) {
      this.loading_login_btn = true
      this.$store.dispatch(AUTH_REQUEST, { token_permit: p_token_permit }).then(() => {
        this.loading_login_btn = false
        this.$router.push('/')
      }).catch(err => {
        this.loading_login_btn = false
        this.login_msg = this.responseError(err)
        this.show_btn_help = true
        setTimeout(() => {
          this.show_btn_help = false
        }, 10000)
      })
    },
    loginWithGoogle() {
      this.loading_login_btn = true
      this.googleActivate()
      setTimeout(() => { // ak sa do minuty neprihlasi, nastav loading na false
        this.loading_login_btn = false
      }, 60000)
    },
    showHelp() {
      this.login_msg = this.forgot_pass_msg
      this.show_btn_help = true
      setTimeout(() => {
        this.show_btn_help = false
      }, 10000)
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
