/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import { AUTH_REQUEST } from '../store/actions/auth'

export default {
  data() {
    return {
      default_timeout: 60,
      timeout: this.default_timeout, // odpocet v sekundach, po tuto dobu sa bude pokusat ziskat token na zaklade login_id
      login_id: undefined,
      timer_id: undefined, // clearTimeout
    }
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    generateGoogleToken(p_data) {
      const oauth = {
        endpoint: 'https://accounts.google.com/o/oauth2/v2/auth', // adresa endpointu pre autentifikáciu OAuth 2.0 u služby Google
        access_type: 'offline', // určuje, že žiadame prístupový token, ktorý môžeme použiť aj v budúcnosti bez nutnosti interakcie s používateľom
        scope: p_data.scopes, // definuje rozsah povolení, ktoré žiadame pre prístup k Google API. napr.: ['https://www.googleapis.com/auth/profile']
        response_type: 'code', // hovorí, že očakávame autentifikačný kód ako odpoveď na tento požiadavok
        client_id: p_data.client_id, // identifikátor klienta, ktorý je vygenerovaný pri vytvorení OAuth klienta v službe Google Cloud Platform
        redirect_uri: p_data.redirect_uri, // určuje URI, kam bude používateľ presmerovaný po úspešnej autentifikácii
        login_id: p_data.login_id || undefined, // voliteľný parameter, ktorý môže obsahovať dodatočné informácie, ktoré chceme preniesť v autentifikačnom tokene
      }
      const state = oauth.login_id ? `&state=${oauth.login_id}` : ''
      const url = `${oauth.endpoint}?access_type=${oauth.access_type}&scope=${oauth.scope}&response_type=${oauth.response_type}&client_id=${oauth.client_id}&redirect_uri=${oauth.redirect_uri}${state}`
      const w = 800
      const h = 760
      const y = window.outerHeight / 2 + window.screenY - (h / 2)
      const x = window.outerWidth / 2 + window.screenX - (w / 2)
      const popup = window.open(url, 'hireMe - Google Auth', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`)

      // ak uzivatel do 60 sek, neklikne na ucet ktorym sa chce prihlasit, zavri okno, token stejne zmizne na serveri...
      setTimeout(() => {
        popup.close()
      }, 60000)

      // spusti proces ziskania tokenu pre velinu
      this.getAuthToken(p_data.login_id)
    },
    googleActivate() {
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/google/init`,
        data: {},
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config).then(response => {
        if (response.status && response.status === 200) {
          this.generateGoogleToken(response.data.data)
        } else {
          console.error('Chyba! Skúste sa prihlásiť a opakujte akciu prosím.') // TODO do snackbar
        }
      }).catch(err => {
        console.error(`[google.js] | mixins | googleActivate ! err => ${new Error(err)}`)
      })
    },
    deactivateGoogle(p_user_id) {
      const self = this
      self.$confirm('Skutočne deaktivovať prepojenie s gogole účtom?', 'Upozornenie', {
        confirmButtonText: 'Ano',
        cancelButtonText: 'Ne',
        type: 'warning',
      }).then(() => {
        axios({ deactivate_user_id: p_user_id }).then(() => {
          self.$message({
            type: 'success',
            message: 'Prepojenie google účtu bolo zrušené.',
          })
        }).catch(err => {
          console.error('[google.js] | mixins | deactivateGoogle ! err =>', err)
          self.$notify({
            title: 'Info',
            message: 'Deaktivácia zlyhala!',
            type: 'warning',
            duration: 5000,
          })
        })
      })
    },
    getAuthToken(p_login_id) {
      if (!p_login_id) {
        console.log('Missing param p_login_id!')

        return
      }

      // ---
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/auth/token/${p_login_id}`,
        data: {},
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config).then(response => {
        if (response.status && response.status === 200) {
          this.$store.dispatch(AUTH_REQUEST, { google_login: true, resp: response }).then(() => {
            if (typeof this.loading_login_btn !== 'undefined') {
              this.loading_login_btn = false
            }
            clearTimeout(this.timer_id)
            this.$router.push('/')
          })
        }
      }).catch(err => {
        if (err.response.status === 401) {
          // skus znovu ak si v timeout limite
          const timer = 5000 // 5 sek
          this.timeout = typeof this.timeout === 'undefined' ? this.default_timeout : this.timeout
          if (this.timeout > 0) {
            this.timeout -= (timer / 1000)
            this.timer_id = setTimeout(() => {
              this.getAuthToken(p_login_id)
            }, timer)
          } else {
            this.timeout = undefined
            console.log('Prihlásenie sa nezdarilo! Skúste sa opäť prihlásiť!')
          }
        } else {
          console.error(`[google.js] | mixins | getAuthToken ! err => ${new Error(err)}`)
        }
      })
    },
  },
}
